
import { Component, Vue } from "vue-property-decorator";
import * as FileServices from "@/api/helpers/File";
import Notify from "@/utils/notifications";
import Regex from "@/utils/regex";
import _ from "underscore";
import * as CSV from "csv-string";
import JsZip from "jszip";

@Component
export default class File extends Vue {
  fullscreenLoading = false;
  downloadLoading = false;
  currentStep = 1;
  limit = 0;
  headers = true;
  fileList: Array<any> = [];
  fileJson: any = {
    name: "",
    items: [],
  };
  splitFileList: Array<any> = [];
  zip = new JsZip();

  splitFiles() {
    if (this.fileList.length >= 1) {
      this.currentStep = 3;
      this.fullscreenLoading = true;
      FileServices.split(this.buildFormData(this.fileList))
        .then(res => {
          this.splitFileList = res.data.data;
          Notify.successful(res.data.message);
        })
        .catch(error => {
          Notify.gebServerError(error);
        })
        .finally(() => {
          this.fullscreenLoading = false;
        });
    }
  }

  download(index: number) {
    const content = this.splitFileList[index].data.join("\n");
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([content]));
    link.setAttribute("download", this.splitFileList[index].name);
    document.body.appendChild(link);
    link.click();
  }

  downloadAll() {
    this.downloadLoading = true;
    const noExtentionName = this.fileJson.name.split(".", 2)[0];
    this.buildZip();
    this.zip
      .generateAsync({
        type: "blob",
      })
      .then(
        blob => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${noExtentionName}.zip`);
          document.body.appendChild(link);
          link.click();
          this.downloadLoading = false;
        },
        err => {
          this.downloadLoading = false;
          Notify.gebServerError(err);
        }
      );
  }

  clearFiles() {
    this.fullscreenLoading = true;
    this.fileList = [];
    this.splitFileList = [];
    (this.$refs.upload as HTMLFormElement).clearFiles();
    this.fullscreenLoading = false;
    this.currentStep = 1;
  }

  handlefileList(file: any, fileList: Array<any>) {
    this.fileList = fileList;
    this.getFileHeaders();
    this.currentStep = 2;
  }

  getFileHeaders() {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const text = CSV.parse(e.target.result);
      if (text.length - 1 <= 0) {
        Notify.warning(`El archivo "${this.fileList[0].name}" está vacío.`);
        this.clearFiles();
        return;
      }
      this.fileJson = {
        name: this.fileList[0].name,
        size: this.fileList[0].size,
        lines: text.length - 1,
        headers: text[0],
        separator: CSV.detect(text[0].toString()),
      };
      this.limit = text.length;
    };
    reader.readAsText(this.fileList[0].raw);
  }

  buildZip() {
    _.each(this.splitFileList, f => {
      this.zip.file(f.name, f.data.join("\n"));
    });
  }

  buildFormData(files: Array<any>) {
    const formData = new FormData();
    formData.append("limit", this.limit.toString());
    formData.append("headers", this.headers ? "1" : "0");
    _.each(files, f => {
      formData.append("files", f.raw);
    });
    return formData;
  }

  handleFileLines(headers: boolean) {
    this.fileJson.lines = headers
      ? this.fileJson.lines - 1
      : this.fileJson.lines + 1;
    this.limit = headers ? this.limit - 1 : this.limit + 1;
  }

  created() {
    const index = "5.2";
    const title = "Dividir archivos";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Archivos",
        to: {
          name: "file",
        },
      },
      {
        text: title,
      },
    ]);
  }
}
